<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
    },
  },
  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: [{
        label: 'Doughnut DataSet',
        data: this.data,
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(75, 192, 192)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        hoverOffset: 4,
      }],
    }, this.options)
    // this.renderChart({
    //   labels: ['WNI', 'WNA'],
    //   datasets: [{
    //     label: 'Doughnut DataSet',
    //     data: [65, 59],
    //     backgroundColor: [
    //       'rgb(255, 99, 132)',
    //       'rgb(54, 162, 235)',
    //       'rgb(255, 205, 86)',
    //     ],
    //     hoverOffset: 4,
    //   }],
    // }, this.options)
  },
}
</script>

<style>
#doughnut-chart {
}
</style>
